.btn {
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
  left: calc(100% - 15px);
  z-index: 2;
  border-radius: 15px;
}

@media screen and (max-width: 1023px) {
  .btn {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    top: calc(100% - 13px);
    left: calc(50% - 13px);
  }
}

.tt:last-child .btn {
  display: none;
}
