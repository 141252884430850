.mainImgContainer {
  display: flex;
  justify-content: center;
  background-image: url('/main_bg4.jpg');
  /*background-size: cover;*/
  background-position: center;
  height: 600px;
  line-height: 1.8;
  margin-top: 90px;
}

@media screen and (max-width: 1023px) {
  .mainImgContainer {
    background-image: url('/main_bg_mobile4.jpg');
    margin-top: 0;
    background-size: cover;
  }
}

.halfBg {
  background: linear-gradient(transparent 65%, #c6e4f2 35%) no-repeat;
}

.linkWrapper {
  background: linear-gradient(#1cb2f8, #1383f9);
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 15px;
  padding: 10px 23px;
  border-radius: 25px;
}

@media screen and (max-width: 1023px) {
  .linkWrapper {
    margin-top: 15px;
    height: 40px;
    padding: 15px;
    border-radius: 20px;
  }
}
