.head_taxmall {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  border-bottom: 1px solid #d8dadc;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
}

.head_taxmall_isTop {
  background: none;
}

@media screen and (min-width: 1024px) {
  .head_taxmall {
    height: 90px;
  }
}

.test {
  background: rgba(0, 0, 0, 0.5);
  height: 0;
  opacity: 0;
  left: 0;
  position: fixed;
  transition: all 0.5s ease-in-out;
  top: 90px;
  z-index: -10;
}

.testActive {
  height: calc(100% - 90px);
  opacity: 1;
  transform: translateY(0);
  z-index: 10;
}

.test2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 1.2rem);
}

.test2:hover .title {
  color: #0065be;
}

.taxmallMobileNav {
  height: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 300ms left cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
}

.mobileFullContainerActive {
  left: 0;
}

.mobileNavContainer {
}

.mobileNavCommon {
  display: flex;
  flex: 1;
}

.mobileNavCommon ul {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mobileNavCommon ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
}

.mobileNavLeft {
  background: #f9f9f9;
}

.mobileNavLeft ul li:hover {
  border-bottom: 3px solid #0065be;
  color: #0065be;
  background: white;
}

.menu svg {
  transition: transform 0.3s ease-in;
}

.menu:hover svg {
  transform: rotate(360deg);
}
